import React, { useState } from 'react';

import { getWithRange, getViolationEventsInRange } from '../../services/getCalls';


const ViewByRange = ({setLoading, setError,setErrorMessage,form,setTables,tables, setForm}) => {
    const [category, setCategory] = useState('shipment')
    
    const handleChange = e => {
        setCategory(e.target.value);
    }
    
    //handles the range inputs 
    const handleRange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    //calls API function for range inputs
    const handleRangeSubmit = ( type, identifier ) => {
        setError(false);
        setErrorMessage('')
        setLoading(true)
        getWithRange( type, identifier, form.rangeStart, form.rangeEnd ).then(res => {
            if (res ) {
                if (res.data) {
                    if ( res.name || !res.response ) {
                        setTables([ res, ...tables ])
                    }
                }
                else {
                    setError(true)
                    setErrorMessage('no data retrieved by API')
                }
            
            }
            setLoading(false)
        }).catch(err => {
            setError( true )
            setErrorMessage( err.response.status + ' ' +err.response.data.message )
            setLoading(false)
        })
    }

    //gets violation data by range
    const handleViolationEvent = () => {
        setError(false);
        setErrorMessage('')
        setLoading(true)
        getViolationEventsInRange(form.rangeStart, form.rangeEnd).then(res => {
            if (res ) {
                if (res.data) {
                    if ( res.name || !res.response ) {
                        setTables([ res, ...tables ])
                    }
                }
                else {
                    setError(true)
                    setErrorMessage(`No data retreived from API`)
                }
            
            }
            setLoading(false)
        }).catch(err => {
            setError( true )
            setErrorMessage( err.response.status + ' ' +err.response.data.message )
            setLoading(false)
        })
    }

    return (
    <>
        <form className='input-box'>
            <label>Category:</label>
            <select className='input' onChange={handleChange}>
                <option value='shipment'>Shipment ID</option>
            </select>
            <label>Start:</label>
            <input value={ form.rangeStart } name='rangeStart' className='input' onChange={ handleRange } />
            <label>End:</label>
            <input value={ form.rangeEnd } name='rangeEnd' className='input' onChange={ handleRange }/>
        </form> 
        <div className='button-box'>
            {category === 'trip' ?
            <>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'trips', 'trips' )}>Trips by #</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'shipment', 'trips' )}>Shipments By Trip #</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'shipment-data', 'trips' )}>Shipment Data by Trip #</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'devices', 'trips' )}>Devices By Trip #</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'accounts', 'trips' )}>Accounts By Trip #</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'device-data', 'trips' )}>Device Data by Trip #</button>
            </>
            : category === 'serial' ?
            <>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'trips', 'device-serial' )}>Trips by Serial Number</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'accounts', 'device-serial' )}>Accounts By Serial Number</button>
            </>
            : category === 'shipment' ?
            <>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'shipment-events', 'shipment-id' )}>Shipment Events by Shipment ID</button>
                <button className='button blue-btn' onClick={ handleViolationEvent }>Shipment Violation Events by Shipment ID</button>
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'shipments', 'shipments' )}>Shipments by ID</button>
            </>
            :
                <button className='button blue-btn' onClick={() => handleRangeSubmit( 'location-data', 'devices' )}>Location Data by Device ID</button>
            } 
        </div>
    </>
    )
}

export default ViewByRange;