import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import Modal from '@material-ui/core/Modal';
import ViewByQual from './ViewByQual.jsx';
import RecycleReport from './RecycleReport.jsx';
import ViewByRange from './ViewByRange.jsx';
import EditForm from './EditForm.jsx';
import spinner from '../../assets/loading.gif';
import './style.css';

const GetBox = ({ tables, setTables }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [goodMessage, setGoodMessage] = useState('')
    const [range, setRangeToggle] = useState('qual');
    const [form, setForm] = useState({
        identifier:'trips',
        array: [],
        rangeStart: '',
        rangeEnd: ''
    });
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState()

    // decodes token and grabs the roleId to check whether the page is read only
    useEffect(() => {
        let token = localStorage.getItem('token');
        token = jwt_decode(token);
        setRole(token['roleId']);
    },[])

    //makes the error message expire
    useEffect(()=> {
        setTimeout(() => setError(false), 10000)
    }, [error])

    // a object made to make the ternarys more dynamic. instead of haveing to change the ternary of every button to fit its needs instead it references the buttonHash to check if it should be displayed
    const buttonHash = {
        shipments: ['shipments', 'shipment-events', 'shipment-violation-events'],
        trips: ['shipmentdata', 'accounts', 'shipments', 'devices', 'trips', 'device-data'],
        devices: ['location-data', 'data', 'serial'],
        accounts: ['locations'],
    }

    return (
        <div className='get-form paper'>
            <h2>View Data</h2>
            <div className='tab-bar'>
                <p className={ range === 'qual' ? 'tab-box tab-box tab-selected' : 'tab-box' } onClick={() => { setError(false); setRangeToggle('qual') }}>View By Qualifier</p>
                <p className={ range === 'range' ? 'tab-box tab-selected' : 'tab-box' } onClick={() => { setError(false); setRangeToggle('range') }}>View By Range</p>
                <p className={ range === 'recycle' ? 'tab-box tab-selected' : 'tab-box' } onClick={() => { setError(false); setRangeToggle('recycle') }}>View Recycle Report</p>
                { role === 1 ? <p className={ range === 'edit' ? 'tab-box tab-selected' : 'tab-box' } onClick={() => { setError(false); setRangeToggle('edit') }}>Edit</p> : null }
                
            </div>
            <div className='input-section'>
            { error ? <p className='error-message' onClick={() => setError(false) }>{ errorMessage }</p> : null}
            { goodMessage ? <p className='good-message' onClick={() => setGoodMessage(false) } >{ goodMessage }</p> : null }
                {/* hides if range is true */}
                { range === 'range' ?
                <ViewByRange setLoading={ bool => setLoading(bool) } setError={ err => setError(err) } setErrorMessage={ err => setErrorMessage(err) } form={ form } setTables={ table => setTables(table) } tables={ tables } setForm={ form => setForm(form) } />
                : range === 'qual' ?
                <ViewByQual setLoading={ bool => setLoading(bool) } setForm={ form => setForm(form) } buttonHash={ buttonHash } setError={ err => setError(err) } setErrorMessage={ err => setErrorMessage(err) } form={ form } setTables={ table => setTables(table) } tables={ tables }/> 
                : range === 'recycle' ? 
                <RecycleReport setLoading={ bool => setLoading(bool) } setForm={ form => setForm(form) } buttonHash={ buttonHash } setError={ err => setError(err) } setErrorMessage={ err => setErrorMessage(err) } form={ form } setTables={ table => setTables(table) } tables={ tables }/>
                :
                <EditForm setGoodMessage={ message => setGoodMessage(message) } setLoading={ bool => setLoading(bool) } setError={ err => setError(err) } setErrorMessage={ err => setErrorMessage(err) } />
                }
                {loading ?
                    <Modal
                    open={loading}
                    onClose={() => setLoading(false)}>
                        <div className='loading-modal'>
                            <img src={spinner} className='spinner' alt='loading gif' />
                            <h2>Loading...</h2>
                        </div>
                    </Modal>
                : null }
            </div>
        </div>
    )
}

export default GetBox;