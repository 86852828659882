import React from 'react';
import {v4 as uuidv4} from 'uuid'
import './style.css'

const TableItem = ({ item, index }) => {

    return (
        <tr className='table-row' key={ uuidv4() }>
            <td key={ uuidv4() } className='table-data bold' >{ index+1 }</td>
                { Object.values( item ).map(( val ) => (
                    <td key={ uuidv4() } className='table-data'>
                        
                        {/* nested ternary to check if val is null, undefined, empty string and formats it into a visible value*/}
                        { val === "" || val === ''? '""':`${val}` }
                        </td>
                )) }
        </tr>
    )
}

export default TableItem;