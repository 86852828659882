import React from 'react';
import Header from '../../components/Header/Header.jsx';
import ViewMenu from '../../components/ViewMenu/ViewMenu.jsx';
import './style.css'

const ViewPage = (props) => {
    return (
        <>
            <Header/>
            <div className='container'>
                <ViewMenu/>
            </div>
        </>
        
    )
}

export default ViewPage;