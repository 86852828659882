import { authApi } from './config';

// updates the dates for all the qualifiers in the given list
export const updateUseByDate = (qualifiers, date) => {
    qualifiers = qualifiers.map(qual => {
        return qual = 'number=' + qual;
    })
    qualifiers = qualifiers.join('&');
    return authApi.patch(`/trips/use-by-date/number?${qualifiers}`, {'useByDate': date}).then(res => {
        return {
            name: 'updated use by data',
            data: res.data
        }
    })
}

export const completeShipments = (name, date) => {
    return authApi.patch(`/shipments/complete?date=${date}&name=${name}`).then(res => {
        return res;
    })
}