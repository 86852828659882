import React, { useState } from 'react';
import GetBox from '../GetBox/GetBox.jsx'
import DataTable from '../DataTable/DataTable.jsx';
import {v4 as uuidv4} from 'uuid';
import './style.css';

const ViewMenu = () => {
    const [tables, setTables] = useState([])

    const handleClear = e => {
        setTables([])
    }
    return (
        <div className='content-container'> 
            <GetBox setTables={table => { setTables(table) }} tables={ tables } />
            {tables.length > 0 ? <button className='button blue-btn table-btn' onClick={ handleClear }>Clear Tables</button> : null}
            
            {/* maps out all the data stored in state each table object contains a name property and data property to allow for descriptive titles */}
            { tables.length > 0 ? tables.map(( table, index ) => (
                <DataTable key={ uuidv4() }  data={ table.data } name={ table.name } index={ index } setTables={ table => setTables(table) } tables={ tables }/>
            )) : <h1 className='empty-box'>Your Tables Will Be Listed Here...</h1> }
        </div>
    )
}

export default ViewMenu;