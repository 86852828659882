import React, {useState} from 'react';
import { getShipmentWithInvalidDate, getUnservedTemp, getWithSerial, getWithImei, getWithName, getIdType, getNumberType, dataParser } from '../../services/getCalls.js';
import { v4 as uuidv4 } from 'uuid';


const ViewByQual = ({setLoading, setForm, form, buttonHash, setError,setErrorMessage,setTables,tables}) => {
    const [id, setId] = useState('');
     //clears all ids from array
     const clearIds = e => {
        e.preventDefault();
        setForm({ ...form, array:[] });
     }

    //updates id input field and useState
    const handleId = e => {
        setId(e.target.value)
    }

    //adds and id to the form array
    const addId = e => {
        e.preventDefault();
        if (id && id.length > 0 && form.array.length <= 49 && !form.array.includes(id)) {
            let newList = id;
            if (form.identifier !== 'accounts') {
                 newList = dataParser(id)
                 setForm({ ...form, array: [...form.array, ...newList] })
            }
            else {
                setForm({ ...form, array: [...form.array, newList] })
            }
            ;    
        }
        setId('')
    }

    // sets the forms identifier category
    const handleChange = e => {
        setForm({ ...form, identifier:e.target.value })
    }

    //removes all instances of a id when it is clicked on
    const handleDelete = id => {
        let newArr = form.array.filter(num => num !== id);
        setForm({ ...form, array: newArr })
    }

    //calls the getwithtripnums function
    const handleSubmit = ( identifier,type, func ) => {
        setError(false);
        setErrorMessage('')
        setLoading(true);
        if (form.array.length > 0) {
            func( form.array, identifier, type).then(res => {
                if (res) {
                    if (( res.name || !res.response ) && res.data.length > 0) {
                        setTables([res, ...tables ])
                        setLoading(false)
                    }
                    else {
                        setError(true);
                        setErrorMessage('404 No Data found using those qualifiers')
                        setLoading(false)
                    } 
                } 
            }).catch(err => {
                setError(true)
                setErrorMessage(err.response ? err.response.status + ' ' + err.response.data.message : `${err}` )
                setLoading(false)
            })
        }
        else {
            setError(true)
            setErrorMessage('please enter at least one qualifier')
            setLoading(false)
        }
    }

    //handles submits from calls that dont take arguments and just run a function
    const handleFunctionSubmit = func => {
        setError(false);
        setErrorMessage('')
        setLoading(true)
        func().then(res => {
            if (res.data && res.data !== 0) {
                setTables([ res, ...tables ])
                setLoading(false)
            }
            else {
                setError(true)
                setErrorMessage(`No data retreived from API`)
                setLoading(false)
            } 
        }).catch(err => {
            setError(true)
            setErrorMessage(err.response ? err.response.status + ' ' + err.response.data.message : `${err}` )
            setLoading(false)
        })
    }

    return (
        <>

            {/* requires one selection for the Find By and 1-500 qualifiers  */}
            <form className='input-box' onSubmit={ addId }>
                    <label>Find By</label>
                    <select onChange={ handleChange } value={form.identifier} className='input'>
                        <option value='trips'>Trip #</option>
                        <option value='shipments'>Shipment ID</option>
                        <option value='devices'>Device Qualifiers</option>
                        
                    </select>
                    <label>Add Qualifier </label>
                    <input onChange={ handleId } value={ id } className='input'/>
                    <button className='button green-btn' onClick={ addId }>Add ID</button>
                    <button className='button red-btn' onClick={ clearIds } >Clear</button>
                </form>
                <div className='button-box'>

                {/* all the buttons for the view calls requested thus far. buttons are rendered dynamically depending on the data in the button hash  */}
                { buttonHash[form.identifier].includes('accounts') ? <button className='button blue-btn' onClick={() => { handleSubmit( form.identifier,'accounts', getNumberType )}}>Get Accounts</button> : null }
                { buttonHash[form.identifier].includes('shipments') ? <button className='button blue-btn' onClick={() => { handleSubmit( form.identifier,'shipments', form.identifier === 'trips' ? getNumberType : getIdType )}}>Get Shipments</button> : null }
                { buttonHash[form.identifier].includes('trips') ? <button className='button blue-btn' onClick={() => { handleSubmit( form.identifier,'trips', getNumberType )}}>Get Trips</button> : null}
                { buttonHash[form.identifier].includes('data') ? <button className='button blue-btn' onClick={() => { handleSubmit( form.identifier,'data', getWithImei )}}>Get Data with IMEI</button> : null }
                { buttonHash[form.identifier].includes('devices') ? <button className='button blue-btn' onClick={() => { handleSubmit( form.identifier,'devices', getNumberType )}}>Get Devices</button>: null}
                { buttonHash[form.identifier].includes('shipment-data') ? <button className='button blue-btn' onClick={() => {handleSubmit( form.identifier,'shipmentdata' )}} >Get Shipment Data by Trip #</button>: null }
                { buttonHash[form.identifier].includes('shipment-violation-events') ? <button className='button blue-btn' onClick={() => { handleSubmit( 'shipment-events','shipment-violation-events', getIdType )}} >Get Shipment Violation Events</button>: null }
                { buttonHash[form.identifier].includes('products') ? <button className='button blue-btn' onClick={() => { handleSubmit( 'shipment-products', 'products', getIdType )}}>Get Products</button>: null }
                { buttonHash[form.identifier].includes('shipment-events') ? <button className='button blue-btn' onClick={() => { handleSubmit( '','shipment-events', getIdType )}} >Get Shipment Events</button>: null }
                { buttonHash[form.identifier].includes('test-criterion') ? <button className='button blue-btn' onClick={() => { handleSubmit( 'shipment-products','test-criterion', getIdType )}} >Get Shipment Test Criterion</button>: null }
                { buttonHash[form.identifier].includes('temp-humidity') ? <button className='button blue-btn' onClick={() => { handleSubmit( form.identifier, 'temp-humidity', getNumberType ) }}>Get temp-humidity</button> : null }
                { buttonHash[form.identifier].includes('location-data') ? <button className='button blue-btn' onClick={() => { handleSubmit(form.identifier, 'location-data', getIdType) }} >Get Location Data with Device ID</button> : null }
                { buttonHash[form.identifier].includes('serial') ? <button className='button blue-btn' onClick={() => { handleSubmit(form.identifier, 'trips', getWithSerial) }} >Get Trips with Serial Number</button> : null }
                { buttonHash[form.identifier].includes('device-data-serial') ? <button className='button blue-btn' onClick={() => { handleSubmit(form.identifier, 'device-data', getWithSerial) }} >Get Device Data</button> : null }
                { buttonHash[form.identifier].includes('device-data') ? <button className='button blue-btn' onClick={() => { handleSubmit(form.identifier, 'device-data', getNumberType) }} >Get Device Data</button> : null }
                { buttonHash[form.identifier].includes('locations') ? <button className='button blue-btn' onClick={() => { handleSubmit(form.identifier, 'locations', getWithName) }} >Get Locations</button> : null }
                {/* buttons that dont require any sort of input */}
                <div className='no-qual-box'> 
                    <h3>No Qualifiers Required</h3>
                    <button className='button lightblue-btn' onClick={() => handleFunctionSubmit( getShipmentWithInvalidDate )}>Get Shipment Data With Invalid Dates</button>
                    <button className='button lightblue-btn' onClick={() => handleFunctionSubmit( getUnservedTemp )}>Get Unserved Temp-Humidity</button>
                </div>
            </div>

            {/* displays the ids in a list format */}
            <div className='edit-list'>
                    {form.array.map( (id, index) => ( 
                            <div key={ uuidv4() } >
                                <p><b>{ index + 1 })</b> { id }</p>
                                <span className='delete-button' onClick={ () => handleDelete(id) }>x</span>
                            </div>
                        ))}
                </div>
        </>
    )
}

export default ViewByQual;