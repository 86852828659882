import React, {useState, useLayoutEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import jsonwebtoken from "jsonwebtoken";


export const getValueFromToken = (key, token) => {
	var decodedData = jsonwebtoken.decode(token, { complete: true });
	if (!decodedData) {
		return false;
	}
	var payload = decodedData.payload;
	return payload[key];
    };
    export const getValueFromLinkToken = (key, token) => {
        var decodedData = jsonwebtoken.decode(token, { complete: true });

        if (!decodedData) {
            return null;
        }

        var payload = decodedData.payload;
        return payload[key];
    };
    const PrivateRoute = ({ component : Component, path, ...rest }) => {
        const history = useHistory();
        const [isLoading, setIsLoading] = useState(true);
        const [isTokenValid, setIsTokenValid] = useState(false);
        const token = localStorage.getItem("token");
        useLayoutEffect(() => {
            setIsLoading(false);
            if (token) {
                const tokenDecoded = getValueFromToken("id", token);
                if (tokenDecoded) {
                    setIsTokenValid(true);
                }
            }
        }, [token]);
    const ComponentToRender = () => {
        if (isLoading) {
            return <div>Loading</div>
        }
        else if (!isLoading && !isTokenValid) {
            return <Redirect to="/" />
        }
        return <Component history={history}/>;
    }
    return <Route {...rest} render={(props) => (ComponentToRender(props))} />
}

export default PrivateRoute;