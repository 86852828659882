import React from 'react';
import { useState } from 'react';
import   {v4 as uuidv4 } from 'uuid';
import Modal from '@material-ui/core/Modal';
import { getNumberType, dataParser, getShipmentsByCompany}  from '../../services/getCalls'
import { updateUseByDate, completeShipments } from '../../services/postCalls';
import DataTable from '../DataTable/DataTable';
import './style.css';

const EditForm  = ({setGoodMessage, setError, setErrorMessage, setLoading}) => {
    const [idList, setIdList] = useState( [] );
    const [id , setId] = useState( '' );
    const [oldData, setOldData] = useState( [] );
    const [modal, toggleModal] = useState( false );
    const [newValue, setNewValue] = useState( '' );
    const [query, setQuery] = useState( 'date' );
    const [maxDeparture, setMaxDeparture] = useState('');


    //changes the query useState and the input value
    const handleQueryChange = e => {
        setNewValue('')
        setQuery(e.target.value);
    }

    // sets the current id in the qualifier inbox
    const handleChange = e => {
        setId(e.target.value)
    }

    //sets the departure change for the complete shipments route
    const handleDepartureChange = e => {
        setMaxDeparture(e.target.value);
    }

    //deletes current id in form
    const handleDelete = id => {
        let newArr = idList.filter(num => num !== id);
        setIdList(newArr)
    }

    //adds the ID or ID List to the idList useState
    const handleAddId = e => {
        setError(false);
        setErrorMessage('')
        e.preventDefault();
        if(id.length > 0) {
            const parsedList = dataParser(id);
            if (parsedList.length <= 501 ) {
                let newList = []
                parsedList.forEach(item => {
                    if(!idList.includes(item) && item.length > 0){
                        newList.push(item);    
                    }
                    setIdList( [...idList, ...newList] )
                })
                
                setId('');
            }
            else{
                setError(401);
                setErrorMessage("id list over 500 id limit please remove ids to insure you are below 500")
            }
        }
        
    }

    const getShipments = e => {
        setError(false);
        setErrorMessage('')
        e.preventDefault();
        setLoading(true);
        getShipmentsByCompany(id, maxDeparture).then(res => {
            setOldData([res.data])
            setLoading(false);
        }).catch(err => {
            setLoading(false)
            setError(true);
            setErrorMessage(err.response.data.message)
        })
    }

    //resets the idList
    const handleClear = e => {
        e.preventDefault();
        setIdList([]);
    }

    //gets data based off the selection and the ids entered
    const getData = e => {
        e.preventDefault();
        setError(false);
        setErrorMessage('')
        setLoading(true);
        if (query === 'date') {
            if (idList.length > 0) {
            getNumberType(idList,'trips', 'trips' ).then(res => {
                if (res.data) {
                    setOldData([res.data])
                }
                else {
                    setOldData([]);
                    setError(true);
                    setErrorMessage('no data retrieved by the API')
                }
                setLoading(false)
            })
            }
            else {
                setLoading(false)
            }
        }
        else {
            if (idList.length > 0) {
                
                }
                else {
                    setLoading(false)
                }
        }
        
        
    }
    //sends edit to the server
    const handleConfirmation = () => {
        setError(false);
        setErrorMessage('');
        setLoading(true);
        toggleModal(false);
        if (query === 'date') {
            updateUseByDate(idList, newValue).then(res => {
                setGoodMessage(`${ res.data.length } rows updated`);
                setOldData([res.data])
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                setError(true);
                setErrorMessage('status ' + err.response.status + err.response.statusText);
            })
        }
        else {
            completeShipments(id, maxDeparture).then(res => {
                setGoodMessage(`${res.data} rows update!`)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                setError(true);
                setErrorMessage('status ' + err.response.status + err.response.statusText);
            })
        }
        
    }

    //pulls up verification modal
    const makeChanges = e => {
        e.preventDefault();
        toggleModal(true);
    }

    return (
        <>
            <div className='box'>
                {/* Setup to be able to make the edit calls. one query selection and 1-500 qualifiers required */}
                <form onSubmit={ handleAddId } className='input-box large'>
                    <select  className='input' value={ query } onChange={ handleQueryChange }>
                            <option value='date'>Update Use-By Date</option>
                            <option value='complete-shipments'>Complete Shipments</option>
                    </select>
                    <label className='edit-label'>{query === 'date' ? 'Enter Trip #:' : 'Enter Company Names'}</label>
                    <input className='input' value={ id } onChange={ handleChange }/>
                    {query === 'complete-shipments' ? 
                    <>
                        <label className='edit-label'>Max Departure Date: </label>
                        <input type='datetime-local' className='input' value={ maxDeparture } onChange={ handleDepartureChange } />
                    </>
                    : null
                    }
                    <div>
                        { query === 'date' ?
                        <>
                            <button className='button blue-btn' onClick={ handleAddId }>Add Trip #</button>
                            <button className='button blue-btn' onClick={ getData }>Get Data</button>
                            <button className='button red-btn' onClick={ handleClear }>Clear</button>
                        </>
                        :
                        <button className='button blue-btn' onClick={ getShipments }>Get Shipments By Company Name</button>
                        }
                        
                    </div>
                    
                    {/* confirmation modal stop accidental edits */}
                    { modal ? 
                    <Modal 
                    open={ modal }
                    onClose={ () => toggleModal(false) }
                    >
                    <div className='modal'>
                        <p>Are you sure you would like to permanently change this columns value?</p>
                        {newValue ? <b>{newValue}</b> : null}
                        <div className='confirm-box'>
                            <button onClick={ handleConfirmation } className='button green-btn'>Yes</button>
                            <button onClick={ () => toggleModal(false) } className='button red-btn'>No</button>
                        </div>
                    </div>
                    </Modal> : null }
                    {/* dynamically displays the correct input type */} 
                    
                    { query !== 'complete-shipments' ?
                    <>
                        <label>Enter the new value for the selected columns:</label>
                        <input type={ query === 'date' ? 'datetime-local' : 'text' } className='input' onChange={ e => setNewValue(e.target.value) }/> 
                    </>
                    : null }
                    <button className='button green-btn' onClick={ makeChanges }>Make Changes</button>
                </form>

                {/* displays the ids in a list format */}
                { idList.length > 0 ? 
                    <div className='edit-list'>
                        { idList.map((item, index) => (
                        <div key={ uuidv4() } >
                            <p><b>{ index + 1 })</b> { item }</p>
                            <span className='delete-button' onClick={ () => handleDelete(item) }>x</span>
                        </div>
                        )) }
                    </div>
                : null }
                
            </div>
            <div className='data-table-box'>
                { oldData.length > 0 ? <DataTable data={ oldData[0] } setTables={ setOldData } tables={ oldData } /> : null }
            </div>
        </>
    )
}

export default EditForm;