import './App.css';
import {Switch, Route} from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute.js';
import LandingPage from './pages/LandingPage/LandingPage.jsx';
import ViewPage from './pages/ViewPage/ViewPage.jsx';

function App() {
  return (
    <div id='App' className='App'>
      <Switch>
        <Route exact path='/' component={ LandingPage } />
        <PrivateRoute exact path='/view' component={ ViewPage } />
      </Switch>
    </div>
  );
}

export default App; 
