import api from './config';

export const signInUser = async ( username, password ) => {
    let response = {};
    response.username = username;
    response.password = password;
    response = JSON.stringify(response);
    return await api.post('/auth/users/login', response, {
        headers : {
            'Content-type':'application/json'
        }}).then(response => {
            if (response.status === 200) {
                return response;
            }
        }).catch(err => {
            return err;
        })
}