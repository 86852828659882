import axios from 'axios';
const handleError = (error) => {
    if (error.response.status === 500) {

        //will eventually redirect user to a error500 page
        // window.location.pathname = '/';
    }
    if (error.response.status === 401 && error.response.config.url !== '/auth/signin') {
        
        // logOut() function will go here when created
        // window.location.pathname = '/';
    }
    return Promise.reject(error);
}

//created to be passed into the interceptor method to allow for some site wide middleware on the api responses
const handleGoodResponse = (res) => {
    return res;
}


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL+process.env.REACT_APP_API_VERSION
});
api.interceptors.response.use(handleGoodResponse, handleError);

export const authApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL+process.env.REACT_APP_API_VERSION,
})

authApi.interceptors.response.use(handleGoodResponse, handleError);
// sets bearer token 
authApi.interceptors.request.use((request) => {
    const token = localStorage.getItem('token');
    request.headers.Authorization = 'Bearer ' + token;
    return request;
}, 
(error) => {
});

export default api;