import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableItem from './TableItem/TableItem.jsx'
import './style.css';
import downArrow from '../../assets/down-arrow.png';
import upArrow from '../../assets/up-arrow.png';
import exit from '../../assets/exit.png';

const DataTable = ({ data, index, name, setTables, tables }) => {

    const [dataToggle, setDataToggle] = useState(true);
    const tableID = uuidv4()
    const [sortState, setSortState] = useState('none');

    //collapses the data field
    const toggleCollapse = () => {
        setDataToggle(!dataToggle);
    }

    //removes table when x is pressed
    const removeTable = () => {
        setDataToggle(false)
        setTimeout(() => {
            if (tables.length <= 1) {
            setTables([])
            }
            else{
                let newTables = [...tables];
                newTables.splice(index, 1)
                setTables(newTables)
            }
        }, 250)  
    }

    //sorts ascending by specified column
    const handleSortAsc = (column) => {
        setSortState('asc')
        const compare = (a,b) => {
            const itemA = a[column];
            const itemB = b[column];
            let comparison = 0;
            if (itemA > itemB) {
                comparison = 1;
            }
            else if (itemA < itemB) {
                comparison = -1;
            }
            else if (itemA === 0 && itemB === null) {
                comparison = 1;
            }
            else if (itemA === null && itemB === 0) {
                comparison = -1;
            }
            return comparison;
        }
        data.sort(compare);
    }

    //sorts decending by specified column
    const handleSortDesc = (column) => {
        setSortState('desc');
        const compare = (a,b) => {
            const itemA = a[column];
            const itemB = b[column];
            let comparison = 0;
            if (itemA > itemB) {
                comparison = -1;
            }
            else if (itemA < itemB) {
                comparison = 1;
            }
            else if (itemA === 0 && itemB === null) {
                comparison = -1;
            }
            else if (itemA === null && itemB === 0) {
                comparison = 1;
            }
            return comparison;
        }
        data.sort(compare);
    }

    // only renders if data is present
    return data ? (
        <>
        <div className='table-box paper'>
            <div className='table-header'>
                <h3>{ name }</h3>

                {/* library to convert the table to exel */}
                <ReactHTMLTableToExcel
                table={tableID}
                className="button blue-btn"
                id={uuidv4()}
                filename="tablexls"
                sheet="tablexls"
                buttonText="Download as XLS"/>
                <p>{ data.length } rows</p>
                <button className='header-button' onClick={ toggleCollapse }>_</button>
                <img className='header-button' src={exit} onClick={ removeTable } alt='remove'/>
            </div>
            
            <table id={tableID} className={ !dataToggle?'data-table table-body-closed':'data-table table-body' }>
                <tbody>
                    <tr className='row-name-box' >
                        <th key={ uuidv4() } className='row-names bold'>#</th>
                        
                        {/* displays all the key names for the columns */}
                        { Object.keys( data[0] ).map(key => (
                            <th key={ uuidv4() } className='row-names'>{ key } {sortState === 'none' || sortState === 'desc' ?
                                <img alt='up arrow' className='arrow' onClick={ () => handleSortAsc(key) } src={upArrow} /> :
                                <img alt='down arrow' className='arrow' onClick={ () => handleSortDesc(key) } src={downArrow}/> }</th>
                        )) }
                    </tr>
                    
                </tbody>
                
                {/* takes array of objects and spreads data through the table */}
                <tbody key={ uuidv4() }>
                    { data.map(( item, index ) => (
                        <TableItem key={ uuidv4() } item={ item } index={ index }/>
                    )) }
                </tbody>
                
            </table>
        </div>
        </>
    ) : null
}

export default DataTable;