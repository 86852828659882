import React from 'react';
import HeaderImage from '../../assets/header.png';
import LoginForm from '../../components/LoginForm/LoginForm.jsx';
import './style.css';

const LandingPage = (props) => {
    return (
        <div className='landing-page'>
            <img src={ HeaderImage } alt='Flashtrak' className ='header-image' />
            <h3>Admin Portal</h3>
            <LoginForm {...props} />
            <div className='temp-footer' ></div>
        </div>
    )
}

export default LandingPage;