import { authApi } from './config';

// arrrays of valid types seperated by number and id
let idType = [ 'devices', 'shipments' ];

// same function as getWithIdentifier but built for range input 
export const getWithRange = (type, qualifier, rangeStart, rangeEnd) => {
    let numType = 'number';
    if (idType.includes(qualifier )) {
        numType = 'id';
    }

    //checks for qualifiers that require a serial number and the first if catches cases where the type is devices
    if (qualifier === 'device-serial' && type === 'devices'){

        //makes api call to get all the data within the given range
        return authApi.get(`${ type }/serial?rangeEnd=${ rangeEnd }&rangeStart=${ rangeStart }`).then(res => {
            return {name: `Get ${type} by Serial Number`,data:res.data};
        })
    }
    else if (qualifier === 'device-serial') {

        // makes api call for inputs with identifiers
        return authApi.get(`${ type }/devices/serial-number?rangeEnd=${ rangeEnd }&rangeStart=${ rangeStart }`).then(res => {
            return {name: `Get ${type} by ${qualifier} #`,data:res.data};
        })
    }

    //checks if type and identifier are equal to correctly change the API route 
    if (type === qualifier) {

        //makes api call to get all the data within the given range
        return authApi.get(`${ type }/${ numType }?rangeEnd=${ rangeEnd }&rangeStart=${ rangeStart }`).then(res => {
            return {name: `Get ${type} by ${qualifier}`,data:res.data};
        }) 
    } 
    else {
        // makes api call for inputs with identifiers
        return authApi.get(`${ type }/${ qualifier }${ qualifier === 'shipment-id' ? "" : '/' + numType }?rangeEnd=${ rangeEnd }&rangeStart=${ rangeStart }`).then(res => {
            return {name: `Get ${type} by ${qualifier} #`,data:res.data};
        })
    }   
}

export const getViolationEventsInRange = (start, end) => {
    return authApi.get(`shipment-violation-events/shipment-events/shipment-id?rangeStart=${start}&rangeEnd=${end}`).then(res => {
        return {name: `Get Shipment Violation Events from ${start}-${end}`, data: res.data}
    })
}

// get call for items that require name format
export const getNumberType = (array, qualifier, type) => {
    array = array.map(num => {
        return 'number=' + num;
    })
    array = array.join('&')
    if (type === qualifier) {
        
        return authApi.get(`/${qualifier}/number?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier}  Number`, data:res.data};
        })
    }
    else {
        return authApi.get(`/${type}/${qualifier}/number?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier}  Number`, data:res.data};
        })
    }
    
}

// get call for items that require id format
export const getIdType = (array, qualifier, type) => {
    array = array.map(num => {
        return 'id=' + num;
    })
    array = array.join('&')
    if (qualifier === 'shipment-products' || type === 'shipment-events' || type === 'shipment-violation-events' ) {
        return authApi.get(`${type}${qualifier ? '/' : ''}${qualifier}/shipment-id?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} id`, data:res.data};
        })
    }
    if (type === qualifier) {
        return authApi.get(`/${qualifier}/id?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} id`, data:res.data};
        })
    }
    else {
        return authApi.get(`/${type}/${qualifier}/id?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} id`, data:res.data};
        })

    }
    
}

// get call for items that require serial-number format
export const getWithSerial = (array, qualifier, type) => {
    array = array.map(num => {
        return 'number=' + num;
    })
    array = array.join('&')
    if (type === qualifier) {
        return authApi.get(`/${qualifier}/serial-number?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} Serial Number`,data:res.data};
        })
    }
    else {
        return authApi.get(`/${type}/${qualifier}/serial-number?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} Serial Number`,data:res.data};
        })
    }
    
}

// get call for items that require name format
export const getWithImei = (array, qualifier, type) => {
    array = array.map(num => {
        return 'imei=' + num;
    })
    array = array.join('&')
    if (type === qualifier) {
        return authApi.get(`/data/${qualifier}/imei/${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} Serial Number`,data:res.data};
        }) 
    }
    else {
        return authApi.get(`/${type}/${qualifier}/imei?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} Serial Number`,data:res.data};
        })
    }
    
}

// get call for items that require name format
export const getWithName = (array, qualifier, type) => {
    array = array.map(num => {
        return 'name=' + num;
    })
    array = array.join('&')
    if (type === qualifier) {
        return authApi.get(`/${qualifier}/name?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} Name`,data:res.data};
        })
    }
    else {
        return authApi.get(`/${type}/${qualifier}/name?${array}`).then(res => {
            return {name: `Get ${type} by ${qualifier} Name`,data:res.data};
        })
    }  
}

//gets all shipments with invalid dates
export const getShipmentWithInvalidDate = () => {
    return authApi.get( 'shipment-data/invalid/date' ).then(res => {
        return {
            name: 'Shipment Data by Invalid Date',
            data: res.data
        }
    })
}

//runs call to get the unserved temp data
export const getUnservedTemp = () => {
    return authApi.get( 'temp-humidity/unserved' ).then(res => {
        return {
            name: 'Unserved Temp-Humidity',
            data: res.data
        }
    })
}

//creates the API call for getting the recycle log and can also take multiple account/device pairs
export const getRecycleReport = (accountList, deviceList) => {
    accountList = accountList.map(account => {
        return account = "account=" + account;
    })
    accountList = accountList.join( '&' );
    if (deviceList.length > 0) {
        
    }
    deviceList = deviceList.map(device => {
        return device = 'device=' + device;
    })
    deviceList = deviceList.join('&');
    return authApi.get( `/recycle-log/report?${ accountList }${deviceList.length > 0 ? '&' + deviceList : ''}` ).then(res => {
        if (res.data) {
            return {
                        name: 'Recycling Report',
                        data: res.data
                    }
        }
        else {
            throw ({
                response: {
                    status: 404,
                    data:{message: 'no data found for that device and account combination'}
                }
            })
        }
    })
}

export const getShipmentsByCompany = (company, departureDate) => {
    return authApi.get(`/shipments/complete?name=${company}&date=${departureDate}`).then(res => {
        if (res.data) {
            return {
                name:'Shipments by Company Name and Departure Date',
                data : res.data
            }
        }
        else {
            throw ({
                response: {
                    status: 404,
                    data: {message: 'no shipments found for that company in that range'}
                }
            })
        }
        
    })
}

export const dataParser = (string) => {
    string = string.split(',');
    return string;
}