import React from 'react';
import {useHistory} from 'react-router'
import Logo from '../../assets/logo.png'
import './style.css';

const Header = () => {
    const history = useHistory();

    const handleLogout = e => {
        history.push('/')
        localStorage.clear();
    }
    return (
        <div className='header'>
            <div>
                <img src={ Logo } className='logo' alt="flashtrak" />
                <p className='logo-text'>Admin Portal</p>
            </div>
            
            <button className='logout' onClick={ handleLogout }>Log Out</button>
        </div>
    )
}

export default Header;