import React, { useState } from 'react';
import './style.css'
import { signInUser } from '../../services/authServices.js';

const initialValues = {
    username: "",
    password: ""
}

const LoginForm = (props) => {
const [form, setForm] = useState(initialValues);
const [error, setError] = useState('');

    const handleChange = (e) => {
        setForm(form => ({ ...form, [e.target.name]: e.target.value }));
    }

    const handleSubmit = e => {
        e.preventDefault();
        signInUser(form.username, form.password).then(response => {
            if ( response.status === 200 ) {
                localStorage.setItem('token', response.data.token);        
                props.history.push('/view');
            }
            else if ( response.status === 500 ) {
                setForm(initialValues);
                setError('server error');
            }
            else {
                setForm(initialValues);
                setError('username or password not found')
            } 
            
        })
    }

    return (
        <div className='login-box'>
            {error !== '' ? <p className='error-message' onClick={ () => setError('') }>{ error }</p> : null}
            <form className="login-form" onSubmit={ handleSubmit } >
                <div className="login-input-box" onClick={ () => setError('') }>
                    <p className="title">Username</p>
                    <input className="input" type='text' name="username" value={ form.username } onChange={ handleChange } />
                </div>
                <div className="login-input-box">
                    <p className="title" >Password</p>
                    <input className="input" type='password' name="password" value={ form.password } autoComplete='false' onChange={ handleChange } />
                </div>
                <button className='button green-btn' type='submit' onClick={ handleSubmit } >Login</button>
            </form>
        </div>
    )
}

export default LoginForm;