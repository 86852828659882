import React, { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import { getRecycleReport } from '../../services/getCalls'


const RecycleReport = ({setLoading, setTables, tables, setError, setErrorMessage}) => {
    const [accountList, setAccountList] = useState([])
    const [deviceList, setDeviceList] = useState([])
    const [recForm, setRecForm] = useState({
        account: '',
        device: ''
    })

    //gets the recycle reports based off form inputs
    const handleRecycleReport = e => {
        setError(false);
        setErrorMessage('')
        getRecycleReport(accountList, deviceList).then(res => {
        if ( res ) {
            if ( res.name || !res.response ) {
                setTables([ res, ...tables ])
            }
        }
        }).catch(err => {
            setError( true )
            if (err.reponse) {
               setErrorMessage( err.response.status + ' ' +err.response.data.message ) 
            }else {
                setErrorMessage('An unexpected Error occured unable to retrieve information.')
            }
        })
        setRecForm({ account: '', device: '' })
    }

    // adds account to the recycle list state
    const addAcct = e => {
        e.preventDefault();
        if (!accountList.includes(recForm.account) && accountList.length < 45 && recForm.account !== '') {
            setAccountList([...accountList, recForm.account])
        }
        setRecForm({...recForm, account: '' })
    }

    // adds device to the recycle list state
    const addDevice = e => {
        
        e.preventDefault();
        if (!deviceList.includes(recForm.deivce) && deviceList.length < 45 && recForm.device !== '') {
            setDeviceList([...deviceList, recForm.device]);
        }
        setRecForm({...recForm, device: '' })   
    }
    //deletes account from array
    const handleAccountDelete = name => {
        let newArr = accountList.filter(n => n !== name);
        setAccountList( newArr );
    }
    //deletes device from array
    const handleDeviceDelete = name => {
        let newArr = deviceList.filter(n => n !== name);
        setDeviceList( newArr );
    }

    const handleRecycleChange = e => {
        setRecForm({...recForm, [e.target.name]: e.target.value});
    }

    const clearForm = e => {
        setRecForm({account: '', device: ''});
        setDeviceList([]);
        setAccountList([]);
    }

    return (
        <>
            <form className='recycle-form' onSubmit={addAcct}>
                <label>* Account Name</label>
                <input className='input' name='account' value={ recForm.account } onChange={ handleRecycleChange } />
                <button className='button green-btn' style={ {width: 200} } onSubmit={addAcct}>Add Account Name</button>
            </form>
            <form className='recycle-form' onSubmit={addDevice}>
                <label >Device Type</label>
                <input className='input' name='device' value={ recForm.device } onChange={ handleRecycleChange } />
                <button className='button green-btn' style={ {width: 200} } onSubmit={ addDevice }>Add Device Name</button>
            </form>
            <div className='recycle-button-box'>
                <button className='button blue-btn report-button' onClick={handleRecycleReport}>Get Recycle Report</button>
                <button className='button red-btn clear-button' onClick={clearForm}>Clear</button>    
            </div>
               
            <div className='list-box'>
                <div className='sub-list'>
                    {accountList.map((account, index) => (
                        <div key={ uuidv4() } className='list-item' >
                            <p><b>{ index + 1 })</b> { account }</p>
                            <span className='delete-button' onClick={() => handleAccountDelete(account) }>x</span>
                        </div>
                    ))}
                </div>
                <div className='sub-list'>
                {deviceList.map((device, index) => (
                        <div key={ uuidv4()} className='list-item' >
                            <p><b>{ index + 1 })</b> { device }</p>
                            <span className='delete-button' onClick={() => handleDeviceDelete(device) }>x</span>
                        </div>
                    ))}
                </div> 
            </div>
        </>
    )
} 


export default RecycleReport;